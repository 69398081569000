import React from "react";

import { container, innerContainer } from "./recommended-by.module.scss";
import { useTranslation } from "react-i18next";
import ReccomendIcon from "../../assets/images/svg/reccomend.svg";
import Title from "../atoms/title";

interface IRecommendedByProps {
  className?: string;
}

const RecommendedBy: React.FC<IRecommendedByProps> = ({ className = "" }) => {
  const { t } = useTranslation();

  return (
    <div className={`${className} ${container}`}>
      <div className={innerContainer}>
        <ReccomendIcon />
        <Title Tag={"h4"} size={"large"}>
          {t("reccomended.by")}
        </Title>
      </div>
      <ul>
        <li>
          <a
            href="https://www.legal500.com/c/poland/intellectual-property/"
            target="_blank"
            rel="noreferrer"
          >
            Legal500 2024
          </a>
        </li>
        <li>
          <a
            href="https://chambers.com/department/sierzant-dudzinski-intellectual-property-europe-7:34:173:1:23344606"
            target="_blank"
            rel="noreferrer"
          >
            Chambers Europe 2024
          </a>
        </li>
        <li>
          <a
            href="https://www.worldtrademarkreview.com/rankings/wtr-1000/profile/firm/sdp-sierzant-dudzinski"
            target="_blank"
            rel="noreferrer"
          >
            WTR1000 2024
          </a>
        </li>
      </ul>
    </div>
  );
};

export default RecommendedBy;
