import React from "react";
import ReactMarkdown from "react-markdown";

import { container, li, ul } from "./we-help-with.module.scss";
import Title from "../atoms/title";
import LiItem from "../atoms/li-item";
import { useTranslation } from "react-i18next";

interface IWeHelpWithProps {
  className?: string;
  weHelpList?: any;
}

const WeHelpWith: React.FC<IWeHelpWithProps> = ({
  className = "",
  weHelpList,
}) => {
  const { t } = useTranslation();

  if (!weHelpList) return null;

  return (
    <div className={`${className} ${container}`}>
      <Title Tag={"h4"} size={"large"}>
        {t("hero.we.help")}
      </Title>

      <ul className={ul}>
        {weHelpList &&
          weHelpList.map((liElem, index) => {
            return (
              <LiItem key={index} className={li}>
                <ReactMarkdown>{liElem?.text?.data?.text}</ReactMarkdown>
              </LiItem>
            );
          })}
      </ul>
    </div>
  );
};

export default WeHelpWith;
