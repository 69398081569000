import React, { useEffect } from "react";
import { graphql } from "gatsby";
import { GatsbyImage, getImage, IGatsbyImageData } from "gatsby-plugin-image";

import { weHelpWith } from "./index.module.scss";
import { IQueryAllResult } from "../models/query-all-result.model";
import { getNodes } from "../utils/get-nodes";
import { IHome } from "../models/home.model";

import MainLayout from "../layouts/main-layout";
import WeHelpWith from "../components/molecules/we-help-with";
import Hero from "../components/molecules/hero";
import { Link } from "gatsby-plugin-react-i18next";
import RecommendedBy from "../components/molecules/recommended-by";

interface IIndexPageProps {
  className?: string;
  readonly data: {
    allStrapiHome: IQueryAllResult<IHome>;
    allFile: IQueryAllResult<IGatsbyImageData>;
  };
}

const IndexPage: React.FC<IIndexPageProps> = ({ data }) => {
  const { allStrapiHome, allFile } = data;

  const homeData = getNodes(allStrapiHome);
  const imageData = getNodes(allFile)[0];
  const imageGatsby = getImage(imageData);

  const {
    naglowek: title,
    naglowek2: subTitle,
    tekst_pod_naglowkiem: text,
    we_help_with,
  } = homeData[0] || {};

  console.log(subTitle);

  return (
    <MainLayout titleSeo={title} descriptionSeo={subTitle}>
      {imageGatsby && (
        <Hero
          title={title}
          subTitle={subTitle}
          text={text}
          image={imageGatsby}
        />
      )}
      <RecommendedBy />

      <WeHelpWith className={weHelpWith} weHelpList={we_help_with} />
    </MainLayout>
  );
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allStrapiHome(filter: { locale: { eq: $language } }) {
      edges {
        node {
          naglowek
          naglowek2
          tekst_pod_naglowkiem
          we_help_with {
            text {
              data {
                text
              }
            }
          }
        }
      }
    }
    allFile(filter: { relativePath: { eq: "hero.png" } }) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData(width: 800, placeholder: BLURRED, quality: 80)
          }
        }
      }
    }
  }
`;

export default IndexPage;
